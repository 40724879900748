import { GetDeparturesQuery } from "../../../generated";
import { DepartureTableBookingViewModel } from "../models/DepartureTableBookingViewModel";

export const transformDepartureBookingToViewModel = (
  booking: GetDeparturesQuery["departuresForDateAndRoute"]["departures"]["0"]["departureBookings"]["0"]
): DepartureTableBookingViewModel => {
  return {
    intermodalBookingNumber: booking.intermodalBookingNumber.toString(),
    account: booking.account,
    id: booking.bookingNumber.toString(),
    iluCode: booking.iluCode,
    loadingNote: booking.loadingNote,
    partnerBookingNumber: booking.trainBooking,
    routeCode: booking.route,
    status: booking.statusCode,
    statusCode: booking.statusCode,
    statusText: booking.statusText,
    vehicleRegistration: booking.vehicleRegistration,
  };
};
