import { useMutation } from "@apollo/client";
import { DateTextInput, TimeTextInput } from "@stenajs-webui/calendar";
import { Box, Column, Heading, Row, Spacing, Txt } from "@stenajs-webui/core";
import {
  Card,
  FlatButton,
  ResultListBanner,
  stenaCheckCircle,
} from "@stenajs-webui/elements";
import { RadioButton } from "@stenajs-webui/forms";
import {
  StandardTable,
  createStandardTableInitialState,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import { parseISO } from "date-fns";
import { orderBy } from "lodash";
import { FC, useMemo, useState } from "react";
import {
  ENABLE_DEPARTURES_BULK_ACTIONS,
  ENABLE_DEPARTURES_SAILING_BULK_ACTIONS,
} from "../../../config/featureFlags";
import {
  CheckInIntermodalBookingsDocument,
  CheckInIntermodalBookingsMutation,
  CheckInIntermodalBookingsMutationVariables,
  GetDeparturesDocument,
  GetDeparturesQuery,
} from "../../../generated";
import { getBannerErrorState } from "../../common/utils/apolloErrorUtils";
import {
  formatDepartureDateTime,
  formatDepartureWithMonth,
} from "../../common/utils/dateUtils";
import { DepartureTableBookingViewModel } from "../models/DepartureTableBookingViewModel";
import { transformDepartureBookingToViewModel } from "../utils/departureTableBookingViewModelUtils";
import { resolveDepartureBookingSortProperty } from "../utils/sortingUtils";
import { DepartureWrapper } from "./DepartureWrapper";
import { config } from "./tableConfig";
import { SelectedItemsActionsPanel } from "@stenajs-webui/panels";

type SailingStatus = "NotDeparted" | "Departed" | "Arrived";

interface Props {
  departure: GetDeparturesQuery["departuresForDateAndRoute"]["departures"]["0"];
  departuresLoading: boolean;
  selectedRoute: string | undefined;
}

export const DepartureCard: FC<Props> = ({
  departure: { departureBookings, sailingId, departureTime },
  departuresLoading,
  selectedRoute,
}) => {
  const [
    checkInIntermodalBookingLegs,
    { loading: checkInLoading, error: checkInError },
  ] = useMutation<
    CheckInIntermodalBookingsMutation,
    CheckInIntermodalBookingsMutationVariables
  >(CheckInIntermodalBookingsDocument);

  const [testSailingStatus, setTestSailingStatus] =
    useState<SailingStatus>("NotDeparted");

  const { tableContext } = useLocalStateTableContext<
    keyof Omit<DepartureTableBookingViewModel, "statusText" | "statusCode">
  >(sailingId, createStandardTableInitialState("id"));

  const { sortOrder, selectedIds } = tableContext.state;

  const items = useMemo(() => {
    let items = departureBookings.map((b) =>
      transformDepartureBookingToViewModel(b)
    );
    items = orderBy(
      items,
      (booking) =>
        resolveDepartureBookingSortProperty(booking, sortOrder.sortBy),
      sortOrder.desc ? "desc" : "asc"
    );
    return items;
  }, [departureBookings, sortOrder.desc, sortOrder.sortBy]);

  const totalActiveBookings = departureBookings.filter(
    (booking) => booking.statusCode !== "X"
  ).length;

  const handleCheckIn = async () => {
    await checkInIntermodalBookingLegs({
      variables: {
        ids: selectedIds.selectedIds,
      },
      refetchQueries: [GetDeparturesDocument],
    });
  };

  const bannerProps = getBannerErrorState(checkInError);
  const headingTitle = formatDepartureDateTime(parseISO(departureTime))
    .concat(" | ")
    .concat(selectedRoute ?? "");

  return (
    <Card maxWidth={1400}>
      {checkInError && bannerProps && <ResultListBanner {...bannerProps} />}
      <Row>
        {ENABLE_DEPARTURES_SAILING_BULK_ACTIONS && (
          <Box flex={1} borderRight={"1px solid var(--lhds-color-ui-200)"}>
            <Box spacing={2} indent={2}>
              <Txt size={"large"} variant="bold">
                {formatDepartureWithMonth(parseISO(departureTime))}
              </Txt>
            </Box>
            <Box spacing={2} indent={2} gap={2}>
              <DepartureWrapper selected={testSailingStatus === "NotDeparted"}>
                <Row gap={2}>
                  <RadioButton
                    className="t_not_departed"
                    size="small"
                    checked={testSailingStatus === "NotDeparted"}
                    onValueChange={() => setTestSailingStatus("NotDeparted")}
                  />
                  <Txt variant="bold">Not departed</Txt>
                </Row>
              </DepartureWrapper>
              <DepartureWrapper selected={testSailingStatus === "Departed"}>
                <Row gap={2}>
                  <Column>
                    <RadioButton
                      className="t_departed"
                      size="small"
                      checked={testSailingStatus === "Departed"}
                      onValueChange={() => setTestSailingStatus("Departed")}
                    />
                  </Column>
                  <Column>
                    <Txt variant="bold">Departed</Txt>
                    <Spacing num={2} />
                    <Txt variant="bold">Est. arrival</Txt>
                  </Column>
                  <Column gap>
                    <DateTextInput />
                    <DateTextInput />
                  </Column>
                  <Column gap>
                    <TimeTextInput />
                    <TimeTextInput />
                  </Column>
                </Row>
              </DepartureWrapper>
              <DepartureWrapper selected={testSailingStatus === "Arrived"}>
                <Row gap={2}>
                  <Column>
                    <RadioButton
                      className="t_arrived"
                      size="small"
                      checked={testSailingStatus === "Arrived"}
                      onValueChange={() => setTestSailingStatus("Arrived")}
                    />
                  </Column>
                  <Column gap={3}>
                    <Txt variant="bold">Arrived</Txt>
                  </Column>
                  <Column gap>
                    <DateTextInput />
                  </Column>
                  <Column gap>
                    <TimeTextInput />
                  </Column>
                </Row>
              </DepartureWrapper>
            </Box>
          </Box>
        )}
        <Box flex={2}>
          {!ENABLE_DEPARTURES_BULK_ACTIONS && (
            <Box indent={2} spacing={2}>
              <Txt size={"large"} variant="bold">
                {formatDepartureWithMonth(parseISO(departureTime))}
              </Txt>
            </Box>
          )}
          {selectedIds.selectedIds.length > 0 ? (
            <SelectedItemsActionsPanel
              rightContent={<Txt> {headingTitle}</Txt>}
              numItemsSelected={selectedIds.selectedIds.length}
              afterLabelContent={
                <FlatButton
                  label="Check in"
                  leftIcon={stenaCheckCircle}
                  onClick={handleCheckIn}
                  loading={checkInLoading || departuresLoading}
                />
              }
            ></SelectedItemsActionsPanel>
          ) : (
            <Box indent={2} spacing={2} height={56}>
              <Row gap={2} alignItems={"baseline"}>
                <Heading>
                  {formatDepartureDateTime(parseISO(departureTime))
                    .concat(" | ")
                    .concat(selectedRoute ?? "")}
                </Heading>
                <Txt size={"large"}>{totalActiveBookings + " bookings"}</Txt>
              </Row>
            </Box>
          )}
          <StandardTable
            config={config}
            items={items}
            tableContext={tableContext}
          />
        </Box>
      </Row>
    </Card>
  );
};
