import { useMutation } from "@apollo/client";
import {
  Box,
  Heading,
  Indent,
  Row,
  SeparatorLine,
  Txt,
  useBoolean,
  useTimeoutState,
} from "@stenajs-webui/core";
import {
  CardHeader,
  FlatButton,
  Label,
  PrimaryButton,
  ResultListBannerProps,
  Tag,
  stenaCheck,
  stenaTimes,
} from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import { useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { RootState } from "../../../config/redux/store";
import {
  CancelIntermodalBookingDocument,
  CancelIntermodalBookingMutation,
  CancelIntermodalBookingMutationVariables,
  CheckInIntermodalBookingLegDocument,
  CheckInIntermodalBookingLegMutation,
  CheckInIntermodalBookingLegMutationVariables,
  FormIntermodalBookingFragment,
  FormIntermodalBookingLegFragment,
  GetTodosDocument,
  IntermodalJourneyLeg,
  MarkPartnerBookingAsCompletedLegInput,
  MarkPartnerBookingsAsCompletedDocument,
  MarkPartnerBookingsAsCompletedMutation,
  MarkPartnerBookingsAsCompletedMutationVariables,
  UnassignIntermodalBookingDocument,
  UnassignIntermodalBookingMutation,
  UnassignIntermodalBookingMutationVariables,
  UpdateIntermodalBookingDocument,
  UpdateIntermodalBookingMutation,
  UpdateIntermodalBookingMutationVariables,
  UpdateSailingsForLegsDocument,
  UpdateSailingsForLegsMutation,
  UpdateSailingsForLegsMutationVariables,
} from "../../../generated";
import {
  createMultiBannerFromUpdateResult,
  createSuccessCancelBanner,
  createSuccessCheckInBanner,
  createSuccessUpdateBanner,
} from "../../common/banner/bannerUtils";
import {
  ValidationError,
  getBannerErrorState,
} from "../../common/utils/apolloErrorUtils";
import {
  getIntermodalBookingFormModel,
  initializeBookingForm,
} from "../redux/formSlice";
import {
  safeTransformIntermodalFormModelToIntermodalBookingInput,
  transformIntermodalFormModel,
} from "../redux/transformers";
import { getStatusTag } from "../utils/statusUtils";
import { BookingActions } from "./BookingDetailsActions";
import { BookingDetailsBanners } from "./BookingDetailsBanners";

const formatUserId = (userId: string) => {
  const [, suffix] = userId.split("\\");
  return suffix ?? userId;
};

interface Props {
  intermodalBooking: FormIntermodalBookingFragment;
  editable: boolean;
  selectedBooking:
    | FormIntermodalBookingFragment
    | FormIntermodalBookingLegFragment;
  showUpdateButton: boolean;
}

export function BookingDetailsCardHeader({
  intermodalBooking,
  editable,
  selectedBooking,
  showUpdateButton,
}: Props) {
  const [isBookingInfoOpen, openBookingInfo, closeBookingInfo] =
    useBoolean(false);

  const dispatch = useDispatch();
  const store = useStore<RootState>();

  const [error, setError] = useState<ValidationError | null>(null);
  const [tempBannerProps, setTempBanner] =
    useTimeoutState<ResultListBannerProps | null>(null, 3000);

  const [
    updateBooking,
    {
      loading: updateLoading,
      error: updateError,
      reset: resetUpdate,
      data: updateResult,
    },
  ] = useMutation<
    UpdateIntermodalBookingMutation,
    UpdateIntermodalBookingMutationVariables
  >(UpdateIntermodalBookingDocument);

  const [unassignIntermodalBooking, { loading: unassignLoading }] = useMutation<
    UnassignIntermodalBookingMutation,
    UnassignIntermodalBookingMutationVariables
  >(UnassignIntermodalBookingDocument);

  const [
    cancelIntermodalBooking,
    {
      loading: cancelIntermodalBookingLoading,
      error: cancelError,
      reset: resetCancel,
    },
  ] = useMutation<
    CancelIntermodalBookingMutation,
    CancelIntermodalBookingMutationVariables
  >(CancelIntermodalBookingDocument);

  const [
    checkInIntermodalBookingLeg,
    { loading: checkInLoading, error: checkInError, reset: resetCheckIn },
  ] = useMutation<
    CheckInIntermodalBookingLegMutation,
    CheckInIntermodalBookingLegMutationVariables
  >(CheckInIntermodalBookingLegDocument);

  const [
    markPartnerBookingsAsCompleted,
    {
      loading: markAsCompletedLoading,
      error: markAsCompletedError,
      reset: resetMarkAsCompleted,
    },
  ] = useMutation<
    MarkPartnerBookingsAsCompletedMutation,
    MarkPartnerBookingsAsCompletedMutationVariables
  >(MarkPartnerBookingsAsCompletedDocument);

  const [
    updateSailingsForLegs,
    {
      loading: updateSailingsForLegsLoading,
      error: updateSailingsForLegsError,
      reset: resetUpdateSailingsForLegs,
    },
  ] = useMutation<
    UpdateSailingsForLegsMutation,
    UpdateSailingsForLegsMutationVariables
  >(UpdateSailingsForLegsDocument);

  const resetAll = () => {
    resetUpdate();
    resetCancel();
    resetCheckIn();
    resetMarkAsCompleted();
    resetUpdateSailingsForLegs();
  };

  const onRequestSave = async () => {
    resetAll();
    setError(null);
    const model = getIntermodalBookingFormModel(store.getState());
    if (!model) {
      return;
    }
    const intermodalBookingInput =
      safeTransformIntermodalFormModelToIntermodalBookingInput(model);

    try {
      if (intermodalBookingInput.state === "error") {
        setError(new ValidationError([intermodalBookingInput.error.message]));
        return;
      }

      const result = await updateBooking({
        variables: {
          intermodalBookingInput: intermodalBookingInput.result,
        },
        refetchQueries: [GetTodosDocument],
      });

      const intermodalBookingResult =
        result.data?.updateIntermodalBooking.intermodalBooking;

      if (intermodalBookingResult) {
        setTempBanner(createSuccessUpdateBanner());
        store.dispatch(
          initializeBookingForm(
            transformIntermodalFormModel(intermodalBookingResult)
          )
        );
      }
    } catch {
      // error is handled internally by Apollo
    }
  };

  const onUnassign = async () => {
    resetAll();
    try {
      const result = await unassignIntermodalBooking({
        variables: {
          id: intermodalBooking.id,
        },
        refetchQueries: [GetTodosDocument],
      });

      const intermodalBookingResult =
        result.data?.unassignIntermodalBooking.intermodalBooking;

      if (intermodalBookingResult) {
        setTempBanner(createSuccessUpdateBanner());
        dispatch(
          initializeBookingForm(
            transformIntermodalFormModel(intermodalBookingResult)
          )
        );
      }
    } catch {
      // error is handled internally by Apollo
    }
  };

  const onMarkPartnerBookingsAsCompleted = async (
    legs: MarkPartnerBookingAsCompletedLegInput[]
  ) => {
    resetAll();
    try {
      const result = await markPartnerBookingsAsCompleted({
        variables: {
          id: intermodalBooking.id,
          legs,
        },
        refetchQueries: [GetTodosDocument],
      });

      const intermodalBookingResult =
        result.data?.markPartnerBookingAsCompleted.intermodalBooking;

      if (intermodalBookingResult) {
        setTempBanner(createSuccessUpdateBanner());
        dispatch(
          initializeBookingForm(
            transformIntermodalFormModel(intermodalBookingResult)
          )
        );
      }
    } catch {
      // error is handled internally by Apollo
    }
  };

  const onCancelIntermodalBooking = async () => {
    resetAll();
    try {
      const result = await cancelIntermodalBooking({
        variables: {
          id: intermodalBooking.id,
        },
        refetchQueries: [GetTodosDocument],
      });

      const intermodalBookingResult =
        result.data?.cancelIntermodalBooking.intermodalBooking;

      if (intermodalBookingResult) {
        setTempBanner(createSuccessCancelBanner());
        dispatch(
          initializeBookingForm(
            transformIntermodalFormModel(intermodalBookingResult)
          )
        );
      }
    } catch {
      // error is handled internally by Apollo
    }
  };

  const onCheckInIntermodalBookingLeg = async (bookingLegId: string) => {
    resetAll();
    try {
      const result = await checkInIntermodalBookingLeg({
        variables: {
          intermodalBookingId: intermodalBooking.id,
          legId: bookingLegId,
        },
        refetchQueries: [GetTodosDocument],
      });

      const intermodalBookingResult =
        result.data?.checkInIntermodalBookingLeg.intermodalBooking;

      if (intermodalBookingResult) {
        setTempBanner(createSuccessCheckInBanner());
        dispatch(
          initializeBookingForm(
            transformIntermodalFormModel(intermodalBookingResult)
          )
        );
      }
    } catch {
      // error is handled internally by Apollo
    }
  };

  const onSetIntermodalJourney = async (legs: IntermodalJourneyLeg[]) => {
    resetAll();
    try {
      const result = await updateSailingsForLegs({
        variables: {
          intermodalBookingId: intermodalBooking.id,
          legs: legs.map((leg) => {
            return { legNumber: leg.legNumber, sailingId: leg.sailing.id };
          }),
        },
        refetchQueries: [GetTodosDocument],
      });

      const intermodalBookingResult =
        result.data?.updateSailingsForLegs.intermodalBooking;

      if (intermodalBookingResult) {
        setTempBanner(createSuccessUpdateBanner());
        dispatch(
          initializeBookingForm(
            transformIntermodalFormModel(intermodalBookingResult)
          )
        );
      }
    } catch {
      // error is handled internally by Apollo
    }
  };

  const bannerError =
    updateError ||
    markAsCompletedError ||
    cancelError ||
    checkInError ||
    updateSailingsForLegsError ||
    error;

  const bannerProps =
    createMultiBannerFromUpdateResult(updateResult) ??
    getBannerErrorState(bannerError) ??
    tempBannerProps;

  return (
    <>
      <CardHeader
        text={selectedBooking.id}
        contentAfterHeading={
          <Row alignItems={"center"}>
            <Tag
              icon={
                selectedBooking.status.code === "C" ? stenaCheck : undefined
              }
              variant={getStatusTag(selectedBooking.status.code)}
              label={selectedBooking.status.text}
            />
            <Indent />
            {intermodalBooking.assignedTo != null ? (
              <>
                <Txt variant={"overline"} size={"smaller"}>
                  Assigned to:
                </Txt>
                <Popover
                  disablePadding
                  onClickOutside={closeBookingInfo}
                  visible={isBookingInfoOpen}
                  arrow={false}
                  trigger={"click"}
                  content={
                    <Box width={"360px"}>
                      <Row
                        alignItems={"center"}
                        indent={2}
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Heading variant="h4">Booking information</Heading>
                        <FlatButton
                          leftIcon={stenaTimes}
                          onClick={closeBookingInfo}
                        />
                      </Row>
                      <SeparatorLine />
                      <Row
                        indent={2}
                        spacing={2}
                        justifyContent={"space-between"}
                        alignItems={"end"}
                      >
                        <Label text="Assigned to">
                          <Txt>{intermodalBooking.assignedTo}</Txt>
                        </Label>
                        <FlatButton
                          loading={unassignLoading}
                          loadingLabel="Unassign"
                          label="Unassign"
                          onClick={onUnassign}
                        />
                      </Row>
                    </Box>
                  }
                >
                  <FlatButton
                    label={formatUserId(intermodalBooking.assignedTo)}
                    onClick={openBookingInfo}
                  />
                </Popover>
              </>
            ) : (
              <Txt variant={"overline"} size={"smaller"}>
                Unassigned
              </Txt>
            )}
          </Row>
        }
        contentRight={
          <>
            <BookingActions
              intermodalBooking={intermodalBooking}
              onCancelIntermodalBooking={onCancelIntermodalBooking}
              onCheckInIntermodalBookingLeg={onCheckInIntermodalBookingLeg}
              onMarkPartnerBookingsAsCompleted={
                onMarkPartnerBookingsAsCompleted
              }
              onSetIntermodalJourney={onSetIntermodalJourney}
              isLoadingCancel={cancelIntermodalBookingLoading}
              isLoadingUpdate={updateLoading}
              isLoadingCheckIn={checkInLoading}
              isLoadingMarkAsCompleted={markAsCompletedLoading}
              isLoadingSetJourney={updateSailingsForLegsLoading}
              onRequestSave={onRequestSave}
            />
            {showUpdateButton && (
              <>
                <Indent />
                <PrimaryButton
                  disabled={!editable}
                  leftIcon={stenaCheck}
                  loading={updateLoading}
                  loadingLabel={"Update booking"}
                  label="Update booking"
                  onClick={onRequestSave}
                />
              </>
            )}
          </>
        }
      />
      <BookingDetailsBanners
        statusCode={intermodalBooking.status.code}
        bannerProps={bannerProps}
        bookings={intermodalBooking.bookings}
      />
    </>
  );
}
