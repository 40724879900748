import { TagVariant } from "@stenajs-webui/elements";

export const getStatusTag = (statusCode: string): TagVariant => {
  switch (statusCode) {
    case "X":
      return "error";
    case "GEX":
    case "RFP":
    case "ARR":
    case "OR":
    case "CON":
    case "F":
    case "C":
      return "success";
    case "IP":
    case "UPD":
      return "info";
    case "W":
      return "warning";
    case "ETAP":
    default:
      return "passive";
  }
};

export const bookingIsUpdated = (statusCode: string): boolean => {
  return statusCode === "UPD";
};
