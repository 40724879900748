import { stenaCheck, Tag } from "@stenajs-webui/elements";
import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import { Link } from "../../../common/common-ui/Link";
import { ENABLE_DEPARTURES_BULK_ACTIONS } from "../../../config/featureFlags";
import { getStatusTag } from "../../booking-details/utils/statusUtils";
import { DepartureTableBookingViewModel } from "../models/DepartureTableBookingViewModel";

export const config: StandardTableConfig<
  DepartureTableBookingViewModel,
  keyof Omit<DepartureTableBookingViewModel, "statusText" | "statusCode">
> = {
  keyResolver: (key) => key.id,
  columnOrder: [
    "status",
    "id",
    "routeCode",
    "account",
    "vehicleRegistration",
    "iluCode",
    "partnerBookingNumber",
    "loadingNote",
  ],
  enableExternalSorting: true,
  rowIndent: !ENABLE_DEPARTURES_BULK_ACTIONS,
  showRowCheckbox: ENABLE_DEPARTURES_BULK_ACTIONS,
  showHeaderCheckbox: ENABLE_DEPARTURES_BULK_ACTIONS,
  columns: {
    intermodalBookingNumber: createColumnConfig(
      (item) => item.intermodalBookingNumber
    ),
    partnerBookingNumber: createColumnConfig(
      (item) => item.partnerBookingNumber,
      {
        width: "140px",
        columnLabel: "Train booking",
      }
    ),
    routeCode: createColumnConfig((item) => item.routeCode, {
      width: "60px",
      columnLabel: "Route",
    }),
    id: createColumnConfig((item) => item.id, {
      width: "80px",
      renderCell: ({ item }) => (
        <Link
          className="t_intermodal_booking"
          to={`/bookings/${item.intermodalBookingNumber}`}
          state={{ prevPath: "departures" }}
        >
          {item.id}
        </Link>
      ),
      columnLabel: "Booking",
    }),
    vehicleRegistration: createColumnConfig(
      (item) => item.vehicleRegistration,
      {
        width: "180px",
        columnLabel: "Vehicle registration",
      }
    ),
    iluCode: createColumnConfig((item) => item.iluCode, {
      width: "140px",
      columnLabel: "ILU code",
    }),
    loadingNote: createColumnConfig((item) => item.loadingNote, {
      width: "400px",
    }),
    status: createColumnConfig((item) => item.statusText, {
      width: "130px",
      renderCell: ({ item }) => (
        <Tag
          icon={item.statusCode === "C" ? stenaCheck : undefined}
          label={item.statusText}
          variant={getStatusTag(item.statusCode)}
        />
      ),
    }),
    account: createColumnConfig((item) => item.account, {
      width: "140px",
      columnLabel: "Account",
    }),
  },
};
