import { Text } from "@stenajs-webui/core";
import { stenaCheck, Tag } from "@stenajs-webui/elements";
import { getStatusTag } from "../../../../booking-details/utils/statusUtils";
import { TableColumnSetting } from "../../common/TableColumns";
import { BookingViewModel } from "../../models/BookingViewModel";
import { SpecialPropertyIcon } from "../SpecialPropertyIcon";

export const resolveBookingLegTableCellContent = (
  column: TableColumnSetting,
  booking: BookingViewModel
) => {
  switch (column.id) {
    case "status":
      return (
        <Tag
          label={booking.status.text}
          variant={getStatusTag(booking.status.code)}
          icon={booking.status.code === "C" ? stenaCheck : undefined}
        />
      );
    case "specialProperties":
      return <SpecialPropertyIcon values={booking.specialProperties} />;
    case "id":
    case "customerRef":
    case "customer":
    case "departure":
    case "arrival":
    case "iluCode":
    case "loadingNote":
    case "numberOfPlugins":
    case "routeName":
    case "vehicleReg":
    case "vehicleType":
    case "firstPartnerBookingNumber":
    case "secondPartnerBookingNumber":
      return <Text whiteSpace={"nowrap"}>{booking[column.id]}</Text>;
    default:
      return null;
  }
};
