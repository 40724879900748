import { Select } from "@stenajs-webui/select";
import { FC, useMemo } from "react";
import { routeSelectStyle } from "../../common/styles/routeSelectStyle";
import { GetAllTrainRoutesQuery } from "../../generated";

interface Props {
  onChange: (value: string) => void;
  routesData: GetAllTrainRoutesQuery | undefined;
  loadingRoutes: boolean;
  initialRoute: string | null;
}

export const RouteSelect: FC<Props> = ({
  onChange,
  routesData,
  loadingRoutes,
  initialRoute,
}) => {
  const routeOptions = useMemo(
    () =>
      routesData?.allTrainRoutes?.map((route) => ({
        value: route.id,
        label: route.description,
      })) ?? [],
    [routesData]
  );
  const initialRouteDescription = routesData?.allTrainRoutes.find(
    (route) => route.id === initialRoute
  )?.description;
  const initialRouteOption = initialRouteDescription
    ? { label: initialRouteDescription, value: initialRoute }
    : undefined;

  return (
    <Select
      className="t_route_select"
      styles={routeSelectStyle}
      isLoading={loadingRoutes}
      options={routeOptions}
      onChange={(option) => onChange(option?.value ?? "")}
      value={initialRouteOption}
    />
  );
};
