import { useQuery } from "@apollo/client";
import { useDebounce } from "@stenajs-webui/core";
import { LoadingModal } from "@stenajs-webui/modal";
import { useNavigation, useSearchParams } from "react-router-dom";
import { Filter } from "../../common/components/Filter";
import { usePageTitle } from "../../common/routes/PageTitle";
import {
  GetAllTrainRoutesDocument,
  GetAllTrainRoutesQuery,
  GetAllTrainRoutesQueryVariables,
  GetDeparturesDocument,
  GetDeparturesQuery,
  GetDeparturesQueryVariables,
} from "../../generated";
import { formatServerDate } from "../bookings/utils/dateUtils";
import { RouteSelect } from "../common/RouteSelect";
import { DeparturesList } from "./components/DeparturesList";
import { useDeparturesFilterState } from "./useDeparturesFilterState";

export const DeparturesPage = () => {
  const [filter, setFilter] = useDeparturesFilterState();
  const debouncedFilter = useDebounce(filter, 700);
  const navigation = useNavigation();
  usePageTitle("Departures");
  const [searchParams] = useSearchParams();
  const urlRoute = searchParams.get("route");
  const { loading, error, data } = useQuery<
    GetDeparturesQuery,
    GetDeparturesQueryVariables
  >(GetDeparturesDocument, {
    variables: {
      endDate: formatServerDate(debouncedFilter.endDate),
      startDate: formatServerDate(debouncedFilter.startDate),
      routeCode: debouncedFilter.route ?? "",
    },
    skip: debouncedFilter.route == null,
  });
  const { loading: loadingRoutes, data: routesData } = useQuery<
    GetAllTrainRoutesQuery,
    GetAllTrainRoutesQueryVariables
  >(GetAllTrainRoutesDocument);
  const departures = data?.departuresForDateAndRoute.departures ?? [];
  const selectedRouteFullname = routesData?.allTrainRoutes?.find(
    (route) => route.id === filter.route
  )?.description;
  return (
    <>
      <Filter
        onDateRangeChanged={(dateRange) => {
          setFilter({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          });
        }}
        contentLeft={
          <RouteSelect
            loadingRoutes={loadingRoutes}
            routesData={routesData}
            onChange={(value) => setFilter({ route: value })}
            initialRoute={urlRoute}
          />
        }
        dateRange={{
          endDate: formatServerDate(debouncedFilter.endDate),
          startDate: formatServerDate(debouncedFilter.startDate),
        }}
      />
      <DeparturesList
        departures={departures}
        error={error != null}
        loading={loading}
        selectedRoute={selectedRouteFullname}
      />
      {navigation.state === "loading" && <LoadingModal />}
    </>
  );
};
